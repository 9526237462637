import {
  Avatar,
  Backdrop,
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { URL } from '../../config';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));
const PostsCard = ({
  data,
  handleButtonView,
  handleButtonEdit,
  handleButtonDelete,
}) => {
  const [pic, setPic] = useState(null);
  const [visible, setVisible] = useState(false);
  const classes = useStyles();
  const handleClose = () => {
    setVisible(false);
  };
  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Cover</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Expires At</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Avatar
                    src={`${URL}/media/post/cover/${item.cover.uri}`}
                    alt='Image'
                    style={{
                      height: 80,
                      width: 80,
                      padding: 7,
                      cursor: 'pointer',
                    }}
                    variant='rounded'
                    onClick={() => {
                      setPic(`${URL}/media/post/cover/${item.cover.uri}`);
                      setVisible(true);
                    }}
                  />
                </TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>{new Date(item.expiresAt).toDateString()}</TableCell>
                <TableCell>
                  <Button
                    color='primary'
                    variant='contained'
                    size='large'
                    onClick={() => handleButtonView(item._id)}
                    style={{ marginInline: 12 }}
                  >
                    View
                  </Button>
                  <Button
                    color='primary'
                    variant='contained'
                    size='large'
                    onClick={() => handleButtonEdit(item._id)}
                    style={{ marginInline: 12, backgroundColor: '#00a152' }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant='contained'
                    color='secondary'
                    size='large'
                    onClick={() => handleButtonDelete(item._id)}
                    style={{ marginInline: 12 }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Backdrop
        className={classes.backdrop}
        open={visible}
        onClick={handleClose}
      >
        <Paper
          style={{
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={handleClose} style={{ alignSelf: 'flex-end' }}>
            <MdClose />
          </IconButton>
          <Avatar
            src={'data:image/png;base64,' + pic}
            alt='Image'
            style={{
              height: 700,
              width: 700,
              padding: 7,
            }}
            variant='rounded'
          />
        </Paper>
      </Backdrop>
    </div>
  );
};

export default PostsCard;
